<template>
	<div>
		<!-- <ItemEditNavbar
			@exitPage="exitPage"
			@saveItem="handleSaveItem"
			:saving="itemSaving"
		/> -->
		<VueElementLoading
			:active="itemLoading || itemSaving"
			spinner="ring"
			:text="itemsName.one + (itemLoading ? ' загрузка...' : ' сохранение...')"
			:background-color="'rgba(255, 255, 255, .7)'"
		/>

		<div class="view-wrapper item-page-wrapper">
			<div class="page-top-bg-addition" />

			<div class="mcontainer">
				<h1 class="title page-title">{{ pageTitle }}</h1>
				<div class="view-content-card card">
					<div v-if="loadContent" class="form-wrapper card-content">
						<ItemForm
							ref="ItemFormComponent"
							:item-data="itemData"
							:items-name="itemsName"
							@submit="handleSubmitForm"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { navigation, itemPageMixin /*, initPageDataMixin*/ } from '@/mixins';

export default {
	name: 'SettingsPage',

	components: {
		ItemForm: () => import('./ItemForm.vue')
	},
	mixins: [navigation, itemPageMixin /*, initPageDataMixin*/],

	data() {
		return {
			loadContent: false
		};
	},

	computed: {
		...mapState({
			itemLoading: state => state.settings.isLoading,
			itemSaving: state => state.settings.isSaving,

			itemData: state => state.settings.itemData
		}),

		itemsName() {
			return {
				one: 'Настройки',
				mult: 'Настройки'
			};
		}
	},

	methods: {
		...mapActions({
			// show_overlay: 'show_overlay',
			setup_navbar: 'setup_navbar',

			fetch_item: 'settings/fetch_settings',
			set_item: 'settings/set_settings',
			save_item: 'settings/save_settings'
		})
	},

	mounted() {
		this.fetch_item({ notNotify: true }).then(() => {
			// console.log('ok')
			this.loadContent = true;
		});
	},

	beforeDestroy() {
		this.set_item(null);
		this.setup_navbar({});
	}
};
</script>
